html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
input,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  display: flex;
  line-height: 1;
  box-sizing: border-box;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  font-size: 1rem;
  color: white;
  width: 100%;
  height: 100vh;
  background: #214475;
  min-height: -webkit-fill-available;
}

:root {
  --color-primary: #214475;
  --color-button-primary: #f18660;
  --color-button-hover: #fbb11a;
  --color-button-disabled: #ad583a;
  --color-button-error: #f19898;
  --color-text-placeholder: #122641;
}

button {
  background: var(--color-button-primary);
  color: white;
}

button:hover {
  background: var(--color-button-hover);
  color: white;
}

button:disabled,
button[disabled] {
  background-color: var(--color-button-disabled);
  color: white;
  border: 2px solid white;
}

::-webkit-input-placeholder {
  color: var(--color-text-placeholder);
}
:-moz-placeholder {
  color: var(--color-text-placeholder);
}

input {
  color: var(--color-text-placeholder);
}
