.room {
  display: flex;
  box-sizing: border-box;
  /* flex-basis: 100%; */
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  /* justify-content: space-around; */
  height: 90vh;
  height: 100dvh;
  width: 100vw;
  width: 100dvw;
  // padding: 0 1rem 1rem 1rem;
  padding: 1rem;
  font-size: 1rem;
}
.room__main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-around;
  height: 100%;
  width: 100%;
  max-width: 500px;
  max-height: 700px;
}

.header {
  display: flex;
  align-self: start;
  justify-self: start;
  flex-direction: row;
  padding-top: 1%;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 3rem;
  text-align: center;
  padding-bottom: 1rem;
}
.room-info {
  display: flex;
  flex-direction: column;
  align-items: start;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  // min-width: 30%;
  // max-width: 48%;
  justify-content: start;
  /* padding: 1rem 0 0 0; */
  max-height: 3rem;
  flex-wrap: wrap;
}

.player_info {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 0.5rem;
  max-height: 3rem;
  min-width: 30%;
  max-width: 48%;
  text-align: end;
  flex-wrap: wrap;
}

.player_img {
  display: flex;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 3rem;
  border: 1px solid white;
}

.player_name {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  text-align: center;
  height: 3rem;
}

.room__game-container {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
  font-size: 1rem;
}

.sentence-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
  margin-bottom: 0.8rem;
  font-size: 1rem;
  line-height: 2.3;
  min-height: 50%;
}
.sentence {
  line-height: 2.6;
}

.room__player [placeholder]::-webkit-input-placeholder {
  color: white;
  text-align: center;
}
.room__player[placeholder]:-moz-placeholder {
  text-align: center;
  color: white;
}

/* /////// */

.player_text {
  padding: 0 0.5rem 0 0.2rem;
}

.room__player-punctuation-container {
  display: inline-block;
}

.room__player {
  position: relative;
  display: inline-block;
  transform: translateY(1rem);
}

.room__player input {
  background-color: inherit;
  width: 5rem;
  padding: 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 3rem;
  border: none;
  border-bottom: 1px solid white;
  line-height: 1;
}

.room__player .room__player-text--below {
  font-style: italic;
  margin-top: 0.3rem;
  text-align: center;
  margin-top: -1rem;
}

#avatar {
  animation-duration: 4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-name: swingHead;
  text-indent: -10rem;
  border-bottom: 1px dashed #f19898;
}

#avatar:focus {
  background-position-x: 500%;
  background-color: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid #f19898;
  text-indent: -10rem;
  animation-duration: 0s;
  animation-timing-function: ease-in-out;
  animation-name: swingHead;
}

.room__waiting {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.room__bottom {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.room__bottom-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  // padding: 1rem;
  gap: 0.2rem;
}
.room__input-container--left {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  // height: 5rem;
}
.room__input-container--right {
  display: flex;
  // height: 5rem;
}
.room__input-bottom {
  display: flex;
  height: 2rem;
  font-size: 0.75rem;
  padding: 0 0.4rem;
  text-align: center;
  width: 95%;
  border-radius: 2rem;
  border: 1px solid var(--color-text-placeholder);
}
.room__instructions {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 0.2rem;
  text-align: center;
  justify-content: center;
  font-size: 0.7rem;
  padding-top: 0.3rem;
}
.room__instructions p span {
  color: #f19898;
}
.room__button {
  display: flex;
  height: 2rem;
  width: 6rem;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  cursor: pointer;
  margin-left: 0.2rem;
  border: 2px solid white;
}
.room__waiting-alert {
  display: flex;
  width: 100%;
  height: 5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1rem;
}

@keyframes swingHead {
  0% {
    background-position-y: 30%;
    border-bottom: 1px solid #f19898;
    background-position-x: 40%;
    // border-bottom: 1px solid var(--playerColor);
  }
  25% {
    /* transform: rotate(45deg); */
    /* transform: translateY(1rem); */
    /* background-position: 50%; */
    background-position-y: 50%;
    border-bottom: 1px dashed #f19898;
    // border-bottom: 2px solid var(--playerColor);
  }
  50% {
    /* transform: rotate(0deg); */
    background-position-y: 30%;
    background-position-x: 70%;
    border-bottom: 1px solid #f19898;
    // border-bottom: 1px solid var(--playerColor);
  }
  75% {
    /* transform: rotate(-45deg); */
    background-position-y: 50%;
    border-bottom: 1px dashed #f19898;
    // border-bottom: 2px solid var(--playerColor);
  }
  100% {
    /* transform: rotate(0deg); */
    background-position-y: 30%;
    border-bottom: 1px solid #f19898;
    // border-bottom: 1px solid var(--playerColor);

    background-position-x: 40%;
    /* background: none; */
  }
}

@media only screen and (min-width: 700px) {
  .room {
    justify-content: center;
    align-items: center;

    max-width: 500px;
    max-height: 700px;
  }
  .room-info {
    font-size: 1rem;
    gap: 0.2rem;
  }

  .player_name {
    font-size: 1.2rem;
  }
  .player_img {
    height: 2.2rem;
    width: 2.2rem;
  }

  .sentence {
    line-height: 2.3;
    font-size: 1.2rem;
    padding-bottom: 1rem;
  }
  .room__instructions {
    font-size: 1rem;
  }
  .room__input-bottom {
    height: 2.6rem;
    font-size: 1.1rem;
  }
  .room__button {
    height: 2.6rem;
    font-size: 1.1rem;
    width: 7rem;
  }
}
