.lobby {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  height: 100dvh;
  width: 100vw;
  width: 100dvw;
  text-align: center;
  font-size: calc(0.8rem + 0.3vw);
  min-height: -webkit-fill-available;
  box-sizing: border-box;
  /* padding: 0 1rem 1rem 1rem; */
  padding: 1rem;
}
.lobby h1 {
  font-size: calc(1.1rem + 0.3vw);
  font-weight: 500;
  line-height: 1.5rem;
  margin: 0;
  padding: 2rem 0 0 0;
}

.lobby__players {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 400px;
  max-width: 95vw;
  flex-grow: 1;
  padding: 0.2rem 0;
  flex-grow: 1;
}
.lobby__player {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 90%;
  height: 3.4rem;
}

.lobby__player img {
  width: 2.5rem;
  height: 2.5rem;
}
.lobby__player-name {
  font-size: calc(1rem + 0.3vw);
  font-weight: 500;
  line-height: 1.5rem;
  padding-right: 1.2rem;
}
.lobby__player-ready {
  font-size: 1rem;
  font-weight: 100;
  line-height: 1rem;
  text-align: center;
  width: 3rem;
  color: rgb(54, 206, 8);
}

.lobby-button {
  display: flex;
  height: 2rem;
  width: 8rem;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  cursor: pointer;
  margin-left: 0.2rem;
  border: 2px solid white;
}

.lobby__host-controls {
  display: flex;
  padding: 2rem 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lobby__host-controls hr {
  width: 10rem;
  border: 1px solid white;
}

@media only screen and (min-width: 700px) {
  .lobby {
    max-width: 500px;
    max-height: 700px;
  }
  .lobby-button {
    height: 3rem;
    font-size: 1.1rem;
  }
}
