.welcome {
  background: #214475;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.welcome__login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(80% - 2vw);
  max-width: 400px;
}
.welcome__login-container h1 {
  font-size: 2rem;
  line-height: 2rem;
  padding-bottom: 2rem;
}
.welcome__login-error {
  font-size: 0.75rem;
  line-height: 1.1rem;
  color: #f19898;
  text-align: left;
}

.welcome__login-error-container {
  height: 1rem;
  text-align: left;
  margin-top: 0.3rem;
}

.welcome__input--login {
  display: flex;
  border-radius: 2rem;
  height: 2rem;
  width: 100%;
}
.welcome__input--room {
  display: flex;
  border-radius: 2rem;
  height: 2rem;
  width: 100%;
}

.welcome__bottom-container {
  margin-top: 1rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.welcome__bottom-container--right {
  width: 50%;
  box-sizing: border-box;
  padding-left: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.welcome__bottom-container--left {
  width: 50%;
  box-sizing: border-box;
  padding-right: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-right: #ffffff 2px solid;
}

.welcome__login-button--container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex end;
  width: 100%;
  padding-top: 1rem;
  /*   border: 1px solid white; */
}
.welcome__login-button {
  display: flex;
  height: 2rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  cursor: pointer;
  margin-left: 0.2rem;
  color: white;
  border: 2px solid white;
  box-sizing: border-box;
}
